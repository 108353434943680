body {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial,
    sans-serif;
  background-color: #f1f5f9 !important;
  min-height: 100vh;
}

.input-text {
  height: 200px;
  border-radius: 4px;
  resize: none;
  width: 100%;
}

.model-selection {
  text-align: center;
}

.tweet-search-button {
  margin: 0px 0px 0px 0px;
}

.date-selector-box {
  width: 100%;
  background-color: #f2f2f2;
  text-align: center;
  padding: 10px 0px 10px;
}

.date-selector-line {
  display: inline-block;
  vertical-align: middle;
}

.output-container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 50px;
}

.output {
  margin: 1rem;
  text-align: center;
  border-bottom: 1px solid black;
}
.child {
  display: inline-block;
  padding: 1rem 1rem;
  vertical-align: middle;
}
